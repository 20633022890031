import createQuarterEndWindow from './create-quarter-end-window.gql'
import updateQuarterEndWindow from './update-quarter-end-window.gql'
import createFileUploadTracking from './create-file-upload-tracking.gql'
import updateDashboardUserProviderStatus from './update-dashboard-user-provider-status.gql'
import updateDashboardUserProviderNotes from './update-dashboard-user-provider-notes.gql'
import updateDashboardQuickLinkName from './update-dashboard-quick-link-name.gql'
import updateDashboardQuickLinkIsActive from './update-dashboard-quick-link-is-active.gql'
import createDashboardQuickLink from './create-dashboard-quick-link.gql'
import createDashboardMyDetails from './create-dashboard-my-details.gql'
import createDashboardUserProvider from './create-dashboard-user-provider.gql'
import updateDashboardMyDetailsName from './update-dashboard-my-details-name.gql'
import deleteDashboardMyDetails from './delete-dashboard-my-details.gql'
import updateDashboardUserProviderIsActive from './update-dashboard-user-provider-is-active.gql'

export const CREATE_QUARTER_END_WINDOW = createQuarterEndWindow
export const UPDATE_QUARTER_END_WINDOW = updateQuarterEndWindow
export const CREATE_FILE_UPLOAD_TRACKING = createFileUploadTracking
export const CREATE_DASHBOARD_USER_PROVIDER = createDashboardUserProvider
export const UPDATE_DASHBOARD_USER_PROVIDER_STATUS = updateDashboardUserProviderStatus
export const UPDATE_DASHBOARD_USER_PROVIDER_NOTES = updateDashboardUserProviderNotes
export const UPDATE_DASHBOARD_USER_PROVIDER_IS_ACTIVE = updateDashboardUserProviderIsActive
export const UPDATE_DASHBOARD_QUICK_LINK_NAME = updateDashboardQuickLinkName
export const UPDATE_DASHBOARD_QUICK_LINK_IS_ACTIVE = updateDashboardQuickLinkIsActive
export const CREATE_DASHBOARD_QUICK_LINK = createDashboardQuickLink
export const CREATE_DASHBOARD_MY_DETAILS = createDashboardMyDetails
export const UPDATE_DASHBOARD_MY_DETAILS_NAME = updateDashboardMyDetailsName
export const DELETE_DASHBOARD_MY_DETAILS = deleteDashboardMyDetails
